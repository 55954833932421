.ql-container {
  border: 1px solid var(--chakra-colors-primary-500);
  border-radius: 10px;
  background-color: var(--chakra-colors-background);
  color: var(--chakra-colors-gray-400);
  padding: 0 .5rem
}

.ql-editor.ql-blank::before {
  left: 1.5rem;
}

.ql-bubble .ql-tooltip{
  background-color: var(--chakra-colors-gray-400);
  z-index: 1;
  overflow: visible;
  transform: translateY(-70px);
  box-shadow: 5px 5px 12px 0px #0000004D;
  border-radius: 3px;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  bottom: -6px;
  top: auto;
  border-top: 6px solid var(--chakra-colors-gray-400);
  border-bottom: none;
}

.error .ql-container {
border-color:  var(--chakra-colors-error);
}
